
import { Link } from 'react-router-dom';
import clsx from 'clsx/lite';
const LogoSolana = 'src/assets/logoSolanaPort.svg';

export const Logo = ({ small = false, className }: { small?: boolean, className?: string }) => (
  <Link to={'/'} className={clsx('items-center text-accent1 no-underline flex gap-2', className)}>
    <div className='font-grotesk text-4xl font-bold logo-text-gradient'>
      <img src={LogoSolana} />
    </div>
  </Link>
);