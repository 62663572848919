import { useQuery } from '@tanstack/react-query';
import { useJWT } from '../../state/app-state';
import axios from 'axios';
import { baseUrl } from 'utils/constants';

export const useMeQuery = () => {
  const jwt = useJWT();
  return useQuery({
    queryKey: ['me', jwt],
    enabled: !!jwt,
    queryFn: async () => {
      const { data } = await axios.get(`${baseUrl}/me`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      return data;
    },
  });
};
