import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Logo } from 'ui/components/logo';
import SolanaCard from 'ui/components/solana-card/solana-card';
import styles from './header.module.css';

export const Header: FC = () => {
  return (
    <div className="container sticky top-0 z-10 mx-auto">
      <header className="flex animate-fade-down flex-row flex-wrap items-center justify-between">
        <div className="flex h-fit w-full items-center gap-2">
          <div className="-ml-[36px]">
            <Logo className="animate-fade animate-delay-75" />
          </div>
          <nav className="mx-5 hidden animate-fade flex-row gap-4 animate-delay-150 lg:flex">
            <NavLink
              to="/swap"
              className={({ isActive }) => `${styles.navlink} ${isActive ? styles.active : ''}`}>
              <span className="text-gradient">Swap</span>
            </NavLink>
            <NavLink
              to="/market"
              className={({ isActive }) => `${styles.navlink} ${isActive ? styles.active : ''}`}>
              <span className="text-gradient">Market</span>
            </NavLink>
            <NavLink
              to="/"
              className={({ isActive }) => `${styles.navlink} ${isActive ? styles.active : ''}`}>
              <span className="text-gradient">Earn</span>
            </NavLink>
            <NavLink
              to="/development"
              className={({ isActive }) => `${styles.navlink} ${isActive ? styles.active : ''}`}>
              <span className="text-gradient">Other</span>
            </NavLink>
            <NavLink
              to="/playground"
              className={({ isActive }) => `${styles.navlink} ${isActive ? styles.active : ''}`}>
              <span className="text-gradient">UI Playground</span>
            </NavLink>
          </nav>
          <div className="grow"></div>
          <div className="flex items-center gap-2">
            <div>CERCA</div>
            <div>BUY CRYPTO</div>
            <WalletModalProvider>
              <SolanaCard innerPadding={false} borderSize="sm">
                {/*TODO use the color globals*/}
                <WalletMultiButton
                  style={{ backgroundColor: '#14151FB2', borderRadius: '8px', height: '36px' }}
                />
              </SolanaCard>
            </WalletModalProvider>
          </div>
        </div>
      </header>
    </div>
  );
};
