import { FC } from 'react';
import { PropsWithChildren } from 'react';
import styles from './solana-card.module.scss';

export type SolanaCardProps = PropsWithChildren<{
  innerPadding?: boolean;
  borderSize?: 'md' | 'sm';
}>;

export const SolanaCard: FC<SolanaCardProps> = ({ children, innerPadding = true, borderSize = 'md' }) => {
  return (
    <div className={`${styles.solanaBorder} ${borderSize === 'sm' ? 'p-[6px]' : 'p-4'}`}>
      <div className={styles.glow}></div>
      <div className={`${styles.inner} ${innerPadding && 'p-6'}`}>{children}</div>
    </div>
  );
};

export default SolanaCard;
