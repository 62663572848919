import { FC } from 'react';
import Marquee from 'react-fast-marquee';


export const Trending: FC = () => {

  return (
    <div
      className={`
        flex 
        flex-row 
        items-center 
        text-sm 
      `}
    >
      <Marquee
        className='ml-6'
        speed={40}
        pauseOnHover
        play
      >
        <div className="border-l-[4px] border-l-rosso ps-2 pe-5 py-2 flex items-center gap-2">
          #1
          <div className="flex">
            <img width={24} src="https://terraport.finance/assets/tokens/lunc.png" />
            <img className="-ml-2" width={24} src="https://i.ibb.co/GtmvHT4/logo.png" />
          </div>
          <div>
            <b>LUNC / </b>TERRA
          </div>
          <b className="text-rosso">+0,13%</b>
        </div>

        <div className="border-l-[4px] border-l-verde ps-2 pe-5 py-2 flex items-center gap-2">
          #2
          <div className="flex">
            <img width={24} src="https://terraport.finance/assets/tokens/lunc.png" />
            <img className="-ml-2" width={24} src="https://i.ibb.co/GtmvHT4/logo.png" />
          </div>
          <div>
            <b>LUNC / </b>TERRA
          </div>
          <b className="text-verde">+0,13%</b>
        </div>
      </Marquee>

    </div>
  );
};