import { FC } from 'react';

//TODO add effective links and i18n
export const Footer: FC = () => {
  return (
    <div className="container mx-auto justify-between py-6 xl:flex">
      <div>
        <div className="mb-5 text-lg">Useful links</div>
        <div className="flex gap-4 opacity-75">
          <a>Privacy Policy</a>
          <a>Risk advice</a>
          <div className="flex gap-1">
            Powered by TerraCVita
            <img src="src/assets/terraVitaLogo.svg" alt="terravita-logo" height="18" width="18" />
          </div>
        </div>
      </div>
      <div>
        <div className="my-5 xl:mt-0 xl:text-right">Socials</div>
        <div className="flex gap-4 opacity-75">
          <a>
            <img src="src/assets/icons/telegram.svg" alt="telegram-logo" className="size-6" />
          </a>
          <a>
            <img src="src/assets/icons/medium.svg" alt="medium-logo" className="size-6" />
          </a>
          <a>
            <img src="src/assets/icons/twitter.svg" alt="twitter-logo" className="size-6" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
