import { Pair } from '@rever22411/amm-commands';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { baseUrl } from 'utils/constants';

export const usePairsQuery = () => {
  return useQuery<Pair[]>({
    queryKey: ['pairs'],
    queryFn: async () => {
      const { data } = await axios.get(`${baseUrl}/pairs`);
      return data;
    },
  });
};

export interface PairPayload {
  creatorAddress: string;
  mintAAddress: string;
  mintBAddress: string;
  assetRatio: number;
  vaultAAddress: string;
  vaultBAddress: string;
  observationId: string;
  currentPriceRatio: number;
  address: string;
  poolType: string;
  mintDecimalsA: number;
  mintDecimalsB: number;
}

export const usePostPairMutation = () => {
  return useMutation({
    mutationFn: async (pairPayload: PairPayload) =>
      fetch(`${baseUrl}/pairs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(pairPayload),
      }),
  });
};
