import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'api';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { WalletStateProvider } from 'state/wallet-provider';
import Footer from 'ui/layout/footer/footer';
import { Trending } from 'ui/components/trending';
import { Header } from 'ui/layout/header/header';
import { Queries } from 'ui/layout/queries';

const Swap = lazy(() => import('ui/pages/swap'));

const Development = lazy(() => import('ui/pages/development'));

const SwapDetail = lazy(() => import('ui/pages/market-detail'));

const Trade = lazy(() => import('ui/pages/market/market'));

const Playground = lazy(() => import('ui/pages/playground'));

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex h-dvh w-dvw flex-col items-stretch">
        <WalletStateProvider>
          <Queries />
          <Trending />
          <Header />
          <Suspense fallback={'loading...'}>
            <Routes>
              <Route path="/swap" element={<Swap />} />
              <Route path="/market" element={<Trade />} />
              <Route path="/market/detail" element={<SwapDetail />} />
              <Route path="/development/*" element={<Development />} />
              <Route path="/playground" element={<Playground />} />
              <Route path="/*" element={<Navigate to="/market" replace />} />
            </Routes>
          </Suspense>
          <Footer />
        </WalletStateProvider>
      </div>
    </QueryClientProvider>
  );
};

export default App;
