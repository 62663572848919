import { InvalidateQueryFilters, QueryClient } from '@tanstack/react-query';
import { forEach } from 'lodash';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      retryDelay: 3000,
      staleTime: 5 * 60 * 1000, //5m
      refetchOnWindowFocus: false,
    },
  },
});

export const invalidateQueries = (keys: InvalidateQueryFilters[]) =>
  setTimeout(() => forEach(keys, (key) => queryClient.invalidateQueries(key)), 20);

export const apiFetch = <T>(url: string, init: RequestInit & { jwt?: string }) =>
  fetch(url, {
    ...init,
    headers: { ...init.headers, Authorization: `Bearer ${init.jwt}` },
  }).then((res) => res.json() as T);
