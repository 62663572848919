import { MintToken, Pair } from '@rever22411/amm-commands';
import { reduce } from 'lodash';
import { createStore } from 'zustand-x';

export const marketDetail = createStore('market-detail')<{
  pools: Record<string, Pair>;
  tokens: Record<string, MintToken>;
  pool: Pair | null;
  tokenA: MintToken | null;
  tokenB: MintToken | null;
}>({
  pools: {},
  tokens: {},
  pool: null,
  tokenA: null,
  tokenB: null,
})
  .extendActions((set, get) => ({
    pool: (p: Pair) => {
      set.pool(p);
      set.tokenA(p.mintA);
      set.tokenB(p.mintB);
    },
  }))
  .extendSelectors((state) => ({
    mintAOptions: () =>
      reduce(
        state.pools,
        (acc, pool) => {
          if (pool.address !== state.pool?.address) {
            if (pool.mintAAddress === state.tokenB?.address) {
              acc.push({
                pool,
                token: pool.mintB,
              });
            }
            if (pool.mintBAddress === state.tokenB?.address) {
              acc.push({
                pool,
                token: pool.mintA,
              });
            }
          }
          return acc;
        },
        [] as Array<{ pool: Pair; token: MintToken }>,
      ),
    mintBOptions: () =>
      reduce(
        state.pools,
        (acc, pool) => {
          if (pool.address !== state.pool?.address) {
            if (pool.mintAAddress === state.tokenA?.address) {
              acc.push({
                pool,
                token: pool.mintB,
              });
            }
            if (pool.mintBAddress === state.tokenA?.address) {
              acc.push({
                pool,
                token: pool.mintA,
              });
            }
          }
          return acc;
        },
        [] as Array<{ pool: Pair; token: MintToken }>,
      ),
  }));
