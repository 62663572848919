/* eslint-disable linebreak-style */
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { clusterApiUrl } from '@solana/web3.js';
import { PropsWithChildren } from 'react';
import { StoreUpdater } from './app-state';

const network = WalletAdapterNetwork.Devnet;
// You can also provide a custom RPC endpoint.
const endpoint = clusterApiUrl(network);

export const WalletStateProvider = ({ children }: PropsWithChildren) => {
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={[]} autoConnect>
        <StoreUpdater />
        {children}
      </WalletProvider>
    </ConnectionProvider>
  );
};
