/* eslint-disable linebreak-style */
import { AnchorWallet, useAnchorWallet, useWallet, Wallet } from '@solana/wallet-adapter-react';
import { sha256 } from '@noble/hashes/sha256';
import * as secp from '@noble/secp256k1';
import { useEffect } from 'react';
import { PublicKey } from '@solana/web3.js';
import { AnchorProvider, setProvider } from '@coral-xyz/anchor';
import { createStore } from 'zustand-x';
import { connection, setConnection } from '@rever22411/amm-commands';

// export const useAddress = () => {
//   const { wallet } = useWallet();
//   const address = useMemo(() => wallet?.adapter.publicKey?.toBase58(), [wallet]);
//   return address;
// };

export type AppState = {
  jwt: string | null;
  provider: AnchorProvider | null;
  pubkey: PublicKey | null;
};

export const appState = createStore('app-state')<AppState>({
  jwt: null,
  provider: null,
  pubkey: null,
}).extendActions((set) => ({
  updateJwt: async (wallet: Wallet | null, anchorWallet: AnchorWallet | null) => {
    const addr = wallet?.adapter.publicKey?.toBase58();
    if (wallet && anchorWallet && addr) {
      const privateKey = secp.etc.hashToPrivateKey?.(Buffer.from(addr, 'utf-8'));
      const publicKey = Buffer.from(secp.getPublicKey(privateKey)).toString('base64');

      const header = JSON.stringify({
        alg: 'ES256K',
        typ: 'JWT',
      });

      const message = JSON.stringify({
        expiry: Math.floor(Date.now() / 1000) + 43200,
        publicKey: publicKey,
        address: addr,
      });

      const hash = sha256(Buffer.from(message, 'utf-8'));

      // convert private key to hex
      const signature = (await secp.signAsync(hash, privateKey)).toCompactHex();
      // convert to base64 header and message
      const headerBase64 = Buffer.from(header).toString('base64');
      const messageBase64 = Buffer.from(message).toString('base64');

      const provider = new AnchorProvider(connection, anchorWallet, {
        preflightCommitment: 'processed',
      });
      setProvider(provider);
      // create JWT
      set.jwt(`${headerBase64}.${messageBase64}.${signature}`);
      set.pubkey(new PublicKey(addr));
      set.provider(provider);
    } else {
      set.jwt(null);
      set.pubkey(null);
      set.provider(null);
    }
  },
}));

export const StoreUpdater = () => {
  const { wallet } = useWallet();
  const anchorWallet = useAnchorWallet();
  useEffect(() => {
    appState.set.updateJwt(wallet, anchorWallet ?? null);
    if (anchorWallet) {
      setConnection(anchorWallet, { cluster: 'devnet', chainUrl: 'https://api.devnet.solana.com' });
    }
  }, [anchorWallet, wallet]);
  return null;
};

export const useJWT = appState.use.jwt;
