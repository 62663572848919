import { useMeQuery } from 'api/solanaport/me';
import { usePairsQuery } from 'api/solanaport/pairs';
import { useTokensQuery } from 'api/solanaport/token';
import { keyBy } from 'lodash';
import { useEffect } from 'react';
import { marketDetail } from 'state/market';

export const Queries = () => {
  const { data: me } = useMeQuery();
  const { data: pairs } = usePairsQuery();
  const { data: tokens } = useTokensQuery();

  useEffect(() => {
    console.log('me', me);
  }, [me]);
  useEffect(() => {
    marketDetail.set.pools(keyBy(pairs, 'address'));
    console.log('pools', pairs);
  }, [pairs]);
  useEffect(() => {
    marketDetail.set.tokens(keyBy(tokens, 'address'));
    console.log('tokens', tokens);
  }, [tokens]);

  return null;
};
