/* eslint-disable linebreak-style */
import { APIToken } from '@rever22411/amm-commands';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { baseUrl, PG_LIMIT } from 'utils/constants';

export const useTokensQuery = () => {
  return useQuery({
    queryKey: ['tokens'],
    queryFn: async () => {
      const { data } = await axios.get<APIToken[]>(`${baseUrl}/tokens?`, {});
      return data;
    },
  });
};

export const useTokenSearchQuery = (search: string) => {
  return useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const { data } = await axios.get<APIToken[]>(`${baseUrl}/tokens?`, {
        params: {
          q: search,
          offset: pageParam * PG_LIMIT,
          limit: PG_LIMIT,
        },
      });
      return data;
    },
    queryKey: ['tokens', search],
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
};

export const usePoolTokenSearchQuery = (search: string) => {
  return useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const { data } = await axios.get<APIToken[]>(`${baseUrl}/tokens?`, {
        params: {
          withPool: true,
          q: search,
          offset: pageParam * PG_LIMIT,
          limit: PG_LIMIT,
        },
      });
      return data;
    },
    queryKey: ['tokens', search],
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
};

// Definisci un tipo per i parametri
interface TokenPayload {
  address: string;
  logoURI: string;
  name: string;
  symbol: string;
  mintAddress: string;
  updateAuthorityAddress: string;
  mintAuthorityAddress: string;
  decimals: number;
  supply: string;
  isWrappedSol: boolean;
}

export const usePostTokenMutation = () => {
  return useMutation({
    mutationFn: async (payload: TokenPayload) =>
      fetch(`${baseUrl}/tokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: payload.address,
          name: payload.name,
          symbol: payload.symbol,
          uri: '',
          isMutable: true,
          primarySaleHappened: false,
          sellerFeeBasisPoints: 0,
          editionNonce: 253,
          tokenStandard: '',
          mintAddress: payload.mintAddress,
          metadataAddress: '',
          updateAuthorityAddress: payload.updateAuthorityAddress,
          mintAuthorityAddress: payload.mintAuthorityAddress,
          freezeAuthorityAddress: '',
          decimals: payload.decimals,
          supply: payload.supply,
          isWrappedSol: payload.isWrappedSol,
          currencySymbol: payload.symbol,
          currencyDecimals: payload.decimals,
          currencyNamespace: 'spl-token',
          creators: [],
          logoURI: payload.logoURI,
        }),
      }),
  });
};
